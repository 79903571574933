<template>
  <div>
    <div class="news">
      <div class="news_content">
        <div class="news_header">
          <div class="newstit">{{ currtit }}</div>
       
        </div>
        <div class="new_main" v-html="detail">
        </div>
      </div>
      <div class="news_footer">
        <div class="prevarticle">
          <div class="prevbtn"  @click="prevarticle(prev.id, prev.index)">
           
            <!-- <div class="btncon">
              <img src="../../../assets/images/mobile/上一篇.png" alt="" />
              <span>上一篇</span>
            </div> -->
          </div>
              <div class="prevtit" >
            {{ prev.title }}
          </div>
        </div>
        <div class="nextarticle">
           <div class="nexttit" >
            {{ next.title }}
          </div>
          <div class="nextbtn" @click="nextarticle(next.id, next.index)">
            <!-- <div class="btncon">
             
              <span>下一篇</span>
               <img src="../../../assets/images/mobile/下一篇.png" alt="" />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getbannerdetail } from "@/http/api/user.js";
export default {
  data() {
    return {
      bannerimg: "",
      list: "",
      articleid: "",
      detail: "",
      txt: "",
      currtit: "",
      prev: {
        title: "",

        id: "",
        index: "",
      },
      next: {
        title: "",

        id: "",
        index: "",
      },
    };
  },
  watch: {
    // 如果路由发生变化，再次执行该方法
    $route(to, from) {
      this.getcompany(this.$route.query.id, this.$route.query.index);
    },
  },
  mounted() {
    this.getcompany(this.$route.query.id, this.$route.query.index);
  },
  created() {
    this.articleid = this.$route.query.id;
    // console.log(this.list);
  },
  methods: {
    prevarticle(id, index) {
   

      this.$router.push({
        path: `/m_medicaleducation/infodetail`,
        query: {
          id: id,
          index: index,
          t: Date.now(),
        },
      });
    },
    nextarticle(id, index) {
      // console.log(id, index);

      this.$router.push({
       path: `/m_medicaleducation/infodetail`,
        query: {
          id: id,
          index: index,
          t: Date.now(),
        },
      });
    },
    //获取公司简介
    async getcompany(myid, index) {
      // console.log(myid);
      let params = {
        barId: "7",
      };
      const data = await getbannerdetail(params);
      this.list = data.data.children[0].portalArticleList;
      if (index == 0) {
        this.prev.title = this.list[this.list.length - 1].articleTitle;
        this.prev.id = this.list[this.list.length - 1].articleId;
        this.prev.index = this.list.length - 1;

        this.next.title = this.list[1].articleTitle;
        this.next.id = this.list[1].articleId;
        this.next.index = 1;
      } else if (index == this.list.length - 1) {
        this.prev.title = this.list[Number(index) - 1].articleTitle;
        this.prev.id = this.list[Number(index) - 1].articleId;
        this.prev.index = Number(index) - 1;

        this.next.title = this.list[0].articleTitle;
        this.next.id = this.list[0].articleId;
        this.next.index = 0;
      } else {
        this.prev.title = this.list[Number(index) - 1].articleTitle;
        this.prev.id = this.list[Number(index) - 1].articleId;
        this.prev.index = Number(index) - 1;

        this.next.title = this.list[Number(index) + 1].articleTitle;
        this.next.id = this.list[Number(index) + 1].articleId;
        this.next.index = Number(index) + 1;
      }
      // console.log(this.list);
      var address = "http://36.152.65.166:10008";
      var reg = new RegExp("/dev-api");
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].articleContent = this.list[i].articleContent.replace(
          /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
          function (match, p) {
            return `<img src=' ${address + p.replace(reg, "")}'/>`;
          }
        );
      }

      this.txt = this.list.find(function (item) {
        return item.articleId == myid;
      });
  
      this.detail = this.txt.articleContent;
      this.currtit = this.txt.articleTitle;
    },
  },
};
</script>

<style>
</style>
<style lang="scss" scoped>
.news {
  // height:10rem;
  overflow: auto;
  .news_content {
    width: 7.5rem;
    margin: 0.5rem auto;
    text-align: center;
    .newstit {
      font-size: 0.2rem;
      font-weight: bold;
    }
    .newsinfo {
      margin-top: 0.6rem;
      width: 90%;
      margin: 0 auto;
      height: 0.5rem;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      justify-content: space-between;
      .infoleft {
        width: 20%;
      }
      .inforight {
        width: 20%;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.16rem;
        img {
          margin-right: 0.1rem;
        }
      }
    }
    .new_main {
      padding: 0.1rem;
      ::v-deep p {
        font-size: 0.25rem;
        text-align: justify;
        text-indent: 2em;
      }
      ::v-deep img{
        display: flex;
        margin: 0.1rem auto;
        width: 90%;
      }
      ::v-deep iframe{
        width: 90%;
        height: 306.95px;
      }
    }
   
  }
  .news_footer {
    border-top: 1px solid #d9d9d9;
    font-size: 0.16rem;
    width: 7.5rem;
    margin: 0.5rem auto;
    height: 1.5rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .prevarticle,
    .nextarticle {
      display: flex;
      align-items: center;
    }
     .prevbtn {
      cursor: pointer;
      width: 26px;
      height: 26px;
      margin-right: 18px;
      background: url("~@/assets/images/01首页/上一页1.png");
      background-size: contain;
    }
    .prevbtn:hover {
      background: url("~@/assets/images/01首页/上一页2.png");
      background-size: contain;
    }
    .nextbtn {
      cursor: pointer;
      width: 26px;
      height: 26px;
      margin-left: 18px;
      background: url("~@/assets/images/01首页/下一页1.png");
      background-size: contain;
    }
    .nextbtn:hover {
      background: url("~@/assets/images/01首页/下一页2.png");
      background-size: contain;
    }
    // .prevbtn,.nextbtn {
    //   cursor: pointer;
    //   color: #ffffff;
    //   font-size: 0.2rem;
    //   width: 3.4rem;
    //   height: 0.5rem;
    //   background-color: #0088ea;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   .btncon {
    //     width: 30%;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //   }
    // }
    
  }
}
</style>